import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import SEO from '../components/seo';
import tw from 'twin.macro';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';
import axios from 'axios';
const IndexPage = () => {
  const { handleSubmit, register, errors } = useForm();
  const [message, setMessage] = useState('');
  const onSubmit = useCallback(async (formData) => {
    try {
      await axios('https://v05mjy0d71.execute-api.us-east-2.amazonaws.com/prod/v1/subscribe', {
        method: 'POST',
        data: JSON.stringify({
          ...formData,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setMessage('Thanks for signing up!');
    } catch (error) {
      setMessage('Thanks for signing up!');
    }
  }, []);

  return (
    <>
      <SEO title="Home" />
      <div tw="relative" style={{ backgroundColor: '#f5f5f5' }}>
        <div tw="relative bg-gray-900 shadow" style={{ backgroundColor: '#28272c' }}>
          <div tw="max-w-7xl mx-auto px-4 sm:px-6">
            <div tw="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div tw="w-0 flex-1 flex">
                <a href="#" tw="inline-flex items-center">
                  <svg tw="h-10 w-auto sm:h-10 block" fill="#FFFFFF" viewBox="0 0 34 34">
                    <path d="M31.843,12.465L18.808,6.987c-0.875-0.368-2.308-0.368-3.184,0L2.588,12.465c-0.875,0.368-0.875,0.97,0,1.338l0.259,0.108  v5.248c-0.64,0.257-1.094,0.881-1.094,1.614c0,0.599,0.303,1.127,0.763,1.44c-0.531,1.223-1.47,3.748-0.763,4.967  c0,0,0.953-0.526,1.742-0.526c0.788,0,1.741,0.526,1.741,0.526c0.707-1.219-0.232-3.744-0.763-4.967  c0.46-0.313,0.763-0.841,0.763-1.44c0-0.733-0.454-1.357-1.094-1.614v-4.704l11.481,4.825c0.875,0.368,2.308,0.368,3.184,0  l13.036-5.478C32.719,13.435,32.719,12.833,31.843,12.465z" />
                    <path d="M19.276,20.395c-0.568,0.239-1.3,0.371-2.061,0.371s-1.492-0.131-2.061-0.371l-6.752-2.837l-1.899,6.278  c0,0,4.803,3.454,10.712,3.454s10.712-3.454,10.712-3.454l-1.899-6.278L19.276,20.395z" />
                  </svg>
                  <br />
                  <span tw="pl-2 block text-white">myTRIO Connect</span>
                </a>
              </div>
              <nav tw="hidden md:flex space-x-10">
                {[
                  {
                    url: '',
                    label: '',
                  },
                ].map(({ url, label }) => (
                  <Link
                    tw="text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 transition ease-in-out duration-150"
                    key={url}
                    to={url}
                  >
                    {label}
                  </Link>
                ))}
              </nav>
              <div tw="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
                {/* <a
                href="#"
                tw="whitespace-no-wrap text-base leading-6 font-medium text-gray-500 hover:text-gray-900 transition ease-in-out duration-150"
              >
                Sign in
              </a>
              <span tw="inline-flex rounded-md shadow-sm">
                <a
                  href="#"
                  tw="whitespace-no-wrap inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-gray-700 bg-accent-600 hover:bg-accent-500 focus:outline-none focus:border-accent-700 focus:shadow-outline active:bg-accent-700 transition ease-in-out duration-150"
                >
                  Sign up
                </a>
              </span> */}
              </div>
            </div>
          </div>
        </div>

        <main tw="lg:relative">
          <div tw="mx-auto max-w-7xl w-full pt-16 pb-20 text-left lg:py-48">
            <div tw="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
              <h2 tw="text-4xl tracking-tight leading-10 sm:leading-5 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-5xl">
                You know they can do it.
                <br />
                <span tw="lg:text-6xl text-accent">We&apos;ll help you get them there.</span>
              </h2>
              <p tw="mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                Our new student communication platform helps you serve and interact with your students more efficiently.
                Through our easy to use app, you can communicate with your students and make sure they stay connected to
                your program and the services you provide.
              </p>
              <p tw="mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                Whether text, email, push notifications or live chat, you can easily connect and track your interactions
                in one safe and secure place. MyTRIO Connect puts your TRIO program in your students&apos; palm at any
                time. Spend less time tracking and recording and more time engaging.
              </p>
              <div tw="mt-10 sm:flex justify-start">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <p tw="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
                      Join our e-list and stay in the know
                    </p>
                    <div
                      tw="inline-flex mt-1 mr-4 relative rounded-md shadow-sm"
                      style={Boolean(errors?.email) ? { ...tw`border border-red-500 shadow-outline-red` } : {}}
                    >
                      <div tw="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg tw="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                      </div>
                      <input
                        tw="form-input block w-full pl-10 pr-8 py-3 font-medium md:text-xl sm:text-sm sm:leading-5 md:py-4 md:px-10"
                        placeholder="you@example.com"
                        type="email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        name="email"
                        ref={register({
                          validate: (value) => {
                            if (!isEmail(value)) {
                              return 'We need a valid email address to sign you up.';
                            }
                          },
                        })}
                      />
                    </div>
                    <button
                      type="submit"
                      tw="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-accent hover:bg-accent focus:outline-none focus:border-accent-700 focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Get Notified
                    </button>
                    {Boolean(errors?.email) && (
                      <p tw="mt-3 px-4 py-3 bg-red-100 text-red-500 border border-red-500">{errors.email.message}</p>
                    )}
                    {Boolean(message) && (
                      <p tw="mt-3 px-4 py-3 bg-green-100 text-green-500 border border-green-500">{message}</p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div tw="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
            <img
              tw="absolute inset-0 w-full h-full object-cover object-top"
              src="https://images.unsplash.com/photo-1560810187-2896a259d9f1?ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80"
              alt="graduation"
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default IndexPage;
